function calcDiscount() {
  var totalDiscount = _.toNumber($('#total_discount').val()) || 0;
  $.each($('#form-special-discount').find('.discount-fields'), function (idx, $elm) {
    var specialDiscount = _.toNumber(parseInt($($elm).find('.js-special-discount').val().replace(/,/g, ''), 10)) || 0;
    totalDiscount += specialDiscount;
  });

  $(".form-estimate-sheet .estimate-item-fields").filter(":visible").each(function (idx, $parent) {
    const discountElement = $($parent).find(".estimate_sheet_estimate_items_discount input");
    let discount_value = (discountElement.val() != '' && discountElement.val() != undefined) ? discountElement.val().replace(/,/g, '') : 0;
    let discount = _.toNumber(parseInt(discount_value));
    totalDiscount += discount;
  });
  $('#total_discount').val('￥ ' + totalDiscount.toLocaleString());
}

$(document).on('change', '.estimate_sheet_discounts_amount #js-special-discount, .esimate-sheet-page input', function(){
  calcDiscount()
  estimateSheetCalcTotal()
})

$(document).on('change', '.js-discount', function() {  
  calcDiscount()
})

// $(document).on('change', '.esimate-sheet-page input', function () {
//   estimateSheetCalcTotal(false)
// })

function estimateSheetCalcTotal(isNewPage = false) {
  const marginNumber = _.defaultTo(_.toNumber($('.margin-number-temp').val()), _.toNumber($('#margin-number-temp').val()));
  let totalSellPrice = 0;
  let totalCost = 0;
  let totalAllDiscount = 0;
  let totalAllDiscountFreeTax = 0;
  let totalGrossProfit = 0;
  let summaryTotal = 0;
  let freeTaxTotal = 0;

  const processItem = ($elm, isSellPrice = true) => {
      const getNumericValue = ($el) => {
          const value = $el.val();
          if (value !== undefined) {
              return _.toNumber(value.replace(/,/g, '')) || 0;
          }
          return 0;
      };

      const costPrice = getNumericValue($($elm).find('.js-cost-price'));
      const amount = getNumericValue($($elm).find('.js-amount'));
      const priceDOM = $($elm).find('.js-price');
      let price = !isNewPage ? getNumericValue(priceDOM) : _.toInteger(marginNumber * costPrice);
      
      if (isNewPage) {
          priceDOM.val(price).change();
      }

      const discount = getNumericValue($($elm).find('.js-discount'));
      totalAllDiscount += discount;

      const sellPrice = getNumericValue($($elm).find('.sell-price'));
      if ($($elm).find('.js-is_freetax').is(':checked')) {
          freeTaxTotal += sellPrice;
          totalAllDiscountFreeTax += discount;
      }

      if (isSellPrice) {
          totalSellPrice += sellPrice;
      } else {
          totalCost += sellPrice;
      }

      totalGrossProfit += (price - costPrice) * amount;

      return { price, sellPrice, discount };
  };

  // Process all sell price items
  $('#form-estimate-nested-sell-price .estimate-item-fields:not(:hidden)').each(function (idx, $elm) {
      processItem($elm);
  });

  // Process all cost items
  $('#form-estimate-nested-cost .estimate-item-fields:not(:hidden)').each(function (idx, $elm) {
      processItem($elm, false);
  });

  // Process special discounts
  let totalSpecialDiscount = 0;
  $('#form-special-discount .discount-fields').each(function (idx, $elm) {
      totalSpecialDiscount += _.toNumber($($elm).find('.js-special-discount').val().replace(/,/g, '')) || 0;
  });
  totalAllDiscount += totalSpecialDiscount;

  // Calculate and update totals
  const tax = 0.1;
  summaryTotal = totalCost + totalSellPrice - totalAllDiscount;
  totalGrossProfit = totalGrossProfit - totalAllDiscount;
  const totalWithTax = totalCost + totalSellPrice - freeTaxTotal - totalAllDiscount + totalAllDiscountFreeTax;
  const totalTax = totalWithTax <= 0 ? 0 : Math.round(totalWithTax * tax);

  $('#total-sell-price').html('￥ ' + totalSellPrice.toLocaleString());
  $('#total-cost-price').html('￥ ' + totalCost.toLocaleString());
  $('#total-all-discount').html('￥ ' + totalAllDiscount.toLocaleString());
  $('#total-gross-profit').html('￥ ' + totalGrossProfit.toLocaleString());
  $('#summary-total').html('￥ ' + summaryTotal.toLocaleString());
  $('#tax-included').html('￥ ' + (summaryTotal + totalTax).toLocaleString());

  calcDiscount();
}


function formatNumber(input) {
  let value = input.replace(/\D/g, '');

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function getRawValue(formattedValue) {
  return parseFloat(formattedValue.replace(/,/g, '')) || 0;
}

function set_custom_value(costPriceDOM) {
  let isUsingIME = false;
  costPriceDOM.on('compositionstart', function() {
    isUsingIME = true;
  });

  costPriceDOM.on('compositionend', function() {
      isUsingIME = false;
      $(this).val(formatNumber($(this).val()));
  });

  costPriceDOM.on('input', function() {
    if (!isUsingIME) {
      $(this).val(formatNumber($(this).val()));
    }
  });
}

function removeFile(index) {
  var attachments = document.getElementById("estimate_sheet_documents").files;
  var fileBuffer = new DataTransfer();
  for (let i = 0; i < attachments.length; i++) {
    if (index !== i)
      fileBuffer.items.add(attachments[i]);
  }
  document.getElementById("estimate_sheet_documents").files = fileBuffer.files;
}

$(document).on('change', '#estimate_sheet_documents', function (e) {
  let $container = $(e.target).closest('.d-flex').find('.upload-list');
  $container.children('.upload-item').remove();
  const length = e.target.files.length;
  let size = $container.children('.upload-item').length;
  for (let i = 0; i < length; i++) {
    const name = e.target.files[i].name;
    const temp = "<div class='upload-item'><span class='index'>" + (++size) + ". </span> <span class='file-name'>" + name + "</span>" +
      "<div class='btn-upload-item btn-delete-file'>" +
      "<img src='/assets/icon/Delete.svg' alt='' data-index='" + i + "'/>" +
      "</div>" +
      "</div>";
    $container.append(temp);
  }
});

$(document).on('click', '.btn-delete-file', function (e) {
  $(this).closest('.upload-item').remove();
  removeFile($(e.target).data('index'));
});

$(document).on('change', '[data-react-amount], [data-react-price]', function (event) {
  $(this).attr('autocompleted', 'off');
  var $container = $(event.target).closest('.row');
  let amount = parseInt($container.find('[data-react-amount]').val().replace(/,/g, ''));
  let price = parseInt($container.find('[data-react-price]').val().replace(/,/g, ''));
  var total;
  if(amount > 0 && price > 0) {
    total = amount * price;
  } else  {
    total = 0;
  }


  $container.find('[data-react-total]').val(total.toLocaleString()).trigger('change');

  // Array.from(['.js-cost-price', '.js-price', '.js-sell_price']).forEach((id) => {
  //   $(id).toArray().forEach(function (field) {
  //     new Cleave(field, {
  //       numeral: true,
  //       numeralThousandsGroupStyle: 'thousand'
  //     });
  //   });
  // });
});

$(function () {
  var templateData = {};
  var selectbox;
  var isClickBtnSearchTemplate = false;
  var isBindClickChooseSpecsMaster = false;
  var isCopyPage = $('.page-copy').length > 0;
  assign_order_value();
  bindRemoveOrder();
  show_warehouse();
  reAddClassEstimateItemFields();
  loadCheckboxEstimateItem();
  toggleCheckboxEstimateItem();
  $(document).on('cocoon:after-insert', '.multi-field-wrapper', function (e, insertedItem) {
    let $wrapNestedElm = $(insertedItem).closest('.nested-fields');
    bindRemoveOrder();
    $wrapNestedElm.find('.form-select.select2').select2({
      theme: 'bootstrap',
      placeholder: '選択してください',
      allowClear: true,
      language: {
        noResults: function () {
          return '見つかりません。';
        },
      },
    });
    assign_order_value();
    bindClickAddOrder($wrapNestedElm);
    show_warehouse();
    bindClickChooseSpecsMaster($wrapNestedElm);
    reAddClassEstimateItemFields();
    toggleCheckboxEstimateItem();
  });

  $('#margin-number-temp').on('keydown', function (evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode === 188) return false;
    return true;
  });

  function getSelector() {
    var selector = window.insertingSelector
    if (selector.type == 'acceptingOrder') {
      return $('#' + selector.value);
    } else if (selector.type == 'estimateSheet') {
      return selector.value;
    }
  }

  $(document).on('click', '.btn-choose-product', function (evt) {
    var productId = $(this).attr('value');
    var $container = getSelector();
    var product_type = $(this).attr("product_type");

    $container.find('.js-product-id').attr("product_type", product_type);

    var productId = $(this).val();
    var $estimateItem = $(this).closest('.estimate-item-fields');

    if (productId) {
      $.ajax({
        url: `/products/${productId}/load_data`,
        method: 'GET',
        success: function (res) {
          var $container = getSelector();
          var product = res.product;
          var cost = product.object_product ? product.object_product.cost : 0;
          var price = product.price || 0;
          var product_text = product.name;

          if (window.insertingSelector.type == 'acceptingOrder') {
            $container.find('.js-cost-price').val(cost.toLocaleString());
            $container.find('.js-unit-price').val(price.toLocaleString());
          } else {
            $container.find('.js-cost-price').val(cost.toLocaleString());
            $container.find('.js-price').val(price.toLocaleString());
            var stockAmount = product.stock ? product.stock.amount : 0;
            $estimateItem.find('.js-amount-stock').text(stockAmount.toLocaleString());
          }

          $container.find('.js-product-id').val(productId);
          $container.find('.js-product-text').val(product_text)
          $container.find('.js-is_freetax').attr('checked', product.is_freetax);
        },
      });
    }
    $('#modal-search-product').modal('hide');
  });

  $(document).on('click', '.js-btn-set-note', function () {
    var $wrapEstimateElm = $(this).closest('.estimate-item-fields');
    $('#modal-specs-master-template').attr('data-estimate-id', $wrapEstimateElm.attr('id'));
    $('#modal-specs-master-template').modal('show');
  });

  $(document).on('click', '.js-add-estimate-item', function (e) {
    e.preventDefault();

    setTimeout(function () {
      var isCheckedAll = $('.toggle-check-all-object').is(':checked');
      if (isCheckedAll) {
        $('.toggle-check-all-object')
          .closest('.partner-list')
          .find('.checkbox-estimate-item')
          .prop('checked', isCheckedAll);
      }
    }, 100);
  });

  $(document).on('click', '.js-add-estimate-execution', function (e) {
    e.preventDefault();
    setTimeout(function () {
      var isCheckedAll = $('.toggle-check-all-execution').is(':checked');
      if (isCheckedAll) {
        $('.toggle-check-all-execution')
          .closest('.partner-list')
          .find('.checkbox-estimate-item')
          .prop('checked', isCheckedAll);
      }
    }, 100);
  });

  function assign_order_value() {
    $.each($('.wrap-order-list'), function (index, value) {
      $.each($(value).find('.order-number-txt'), function (order_index, order_value) {
        $(order_value).html('発注 ' + _.toString(order_index + 1));
      });
    });

    $.each($('.wrap-order-list'), function (index, value) {
      $.each($(value).find('.js-order-no'), function (order_index, order_value) {
        $(order_value)
          .val(_.toString(order_index + 1))
          .change();
      });
    });
  }

  $('.toggle-check-all-object').on('click', function () {
    var isCheckedAll = $(this).is(':checked');
    $(this).closest('.partner-list').find('.checkbox-estimate-item').prop('checked', isCheckedAll);
  });

  $('.toggle-check-all-execution').on('click', function () {
    var isCheckedAll = $(this).is(':checked');
    $(this).closest('.partner-list').find('.checkbox-estimate-item').prop('checked', isCheckedAll);
  });

  function toggleCheckboxEstimateItem() {
    $('.checkbox-estimate-item').on('click', function () {
      const checkboxes = $('.checkbox-estimate-item');
      var isChecked = $(this).is(':checked');
      if (!isChecked) {
        $(this)
          .closest('.partner-list')
          .find('.toggle-check-all-object')
          .prop('checked', isChecked);
      } else {
        const allChecked = checkboxes.length === checkboxes.filter(':checked').length;
        $(this).closest('.partner-list').find('.toggle-check-all-object').prop('checked', allChecked);
      }
    });

    $('.checkbox-estimate-item').on('click', function () {
      var isChecked = $(this).is(':checked');
      if (!isChecked) {
        $(this)
          .closest('.partner-list')
          .find('.toggle-check-all-execution')
          .prop('checked', isChecked);
      } else {
        var checkboxEstimateItems = $(this)
          .closest('.partner-list')
          .find('.checkbox-estimate-item')
          .slice(1);
        var elements = [];
        _.forEach(checkboxEstimateItems, function (item) {
          var checked = $(item).is(':checked');
          if (checked) {
            elements.push(item);
          }
        });
        if (checkboxEstimateItems.length == elements.length) {
          $(this)
            .closest('.partner-list')
            .find('.toggle-check-all-execution')
            .prop('checked', true);
        }
      }
    });
  }

  function loadCheckboxEstimateItem() {
    _.forEach($('.partner-list'), function ($elm, idx) {
      var newAction = document.getElementById('new_estimate_sheet');
      var checkboxEstimateItems = $($elm).find('.checkbox-estimate-item').slice(1);
      var elements = [];
      if (newAction && newAction.length > 0) {
        _.forEach(checkboxEstimateItems, function (item) {
          $(item).prop('checked', true);
          elements.push(item);
        });
      } else {
        var firstCheckbox = $($elm).find('.toggle-check-all-execution');
        var checkboxObject = $($elm).find('.toggle-check-all-object');
        _.forEach(checkboxEstimateItems, function (item) {
          var checked = $(item).is(':checked');
          if (checked) {
            elements.push(item);
          }
        });

        if (checkboxEstimateItems.length == elements.length) {
          $($elm).find('.toggle-check-all-execution').prop('checked', true);
          $($elm).find('.toggle-check-all-object').prop('checked', true);
        } else {
          if (firstCheckbox.val()) {
            $($elm).find('.toggle-check-all-execution').prop('checked', false);
          } else {
            $($elm).find('.toggle-check-all-execution').prop('checked', false);
          }

          if (checkboxObject.val()) {
            $($elm).find('.toggle-check-all-object').prop('checked', false);
          } else {
            $($elm).find('.toggle-check-all-object').prop('checked', false);
          }
        }
      }
    });
  }

  function bindRemoveOrder() {
    $('.remove-order-item').on('click', function () {
      $(this).closest('.order-item-clone').remove();
      assign_order_value();
    });
  }

  function bindClickAddOrder($wrapNestedElm) {
    $($wrapNestedElm)
      .find('.btn-add-order')
      .on('click', function () {
        var $orderItem = $('.order-item-clone.d-none').clone();
        $orderItem.removeClass('d-none');
        var currentNumber = $(this).closest('.nested-fields').find('.order-item-clone').length + 1;
        $orderItem.find('span.order-number-txt').html('発注 ' + _.toString(currentNumber));
        $(this).closest('.nested-fields').find('.wrap-order-list').append($orderItem);
      });
  }



  function bindClickChooseSpecsMaster($wrapElem) {
    if (isBindClickChooseSpecsMaster) return;

    $('.js-choose-specs-master').on('click', function () {
      var currentId = $('#modal-specs-master-template').attr('data-estimate-id');
      var $currentEstimateItem = $(`#${currentId}`);
      var specsMaster = $(this).closest('.row').data('values');
      if ($currentEstimateItem) {
        $($currentEstimateItem).find('.line_note').val(specsMaster.specification);
      }

      $('#modal-specs-master-template').modal('hide');
      isBindClickChooseSpecsMaster = true;
    });
  }

  _.forEach($('#form-estimate-nested-sell-price .estimate-item-fields'), function ($elm) {
    bindClickChooseSpecsMaster($elm);
  });

  function reAddClassEstimateItemFields() {
    _.forEach($('.estimate-item-fields'), function ($elm, idx) {
      $($elm).attr('id', `estimate-item-${idx}`);
    });
  }

  $('.btn-open-template').on('click', function () {
    if (isClickBtnSearchTemplate) {
      $('.execution-template-name').val('');
      $('#modal-execution-template .search-execution-template').trigger('click');
    }
    $('#modal-execution-template').modal('show');
    isClickBtnSearchTemplate = true;
  });

  $('.js-duplicate-sheet').on('click', function () {
    $.ajax({
      url: `/estimate_sheets/${$('#button_copy_estimate_sheet').data( "id")}/copy`,
      method: 'post',
      success: function (res) {
        window.location.reload();
      },
    });
    // var originalId = $('.esimate-sheet-page').data('esti-sheet-id');
    // if (originalId) {
    //   window.open(`/estimate_sheets/new?original_id=${originalId}&is_copy=true`, '_blank').focus();
    //   $('#modal_add_new_estimate_sheet').modal('hide');
    //   return;
    // }
    // var columns = $('.esimate-sheet-page').data('attrs');
    // var orderColumns = $('.esimate-sheet-page').data('order-attrs');
    // // es = estimate_sheet
    // var estimates = { es: {} };
    // var modelName = 'estimate_sheet';
    // _.forEach(columns, function (column) {
    //   var val = $('.form-estimate-sheet')
    //     .find("input[name*='" + modelName + '[' + column + "]']:last")
    //     .val();
    //   if (!val)
    //     val = $('.form-estimate-sheet')
    //       .find("select[name*='" + modelName + '[' + column + "]']:last")
    //       .val();
    //   if (!val)
    //     val = $('.form-estimate-sheet')
    //       .find("textarea[name*='" + modelName + '[' + column + "]']:last")
    //       .val();
    //   if (column == 'accept_status') {
    //     val = $('.form-estimate-sheet').find(`input[name*='accept_status']:checked`).val();
    //   }
    //   estimates['es'][column] = val;
    // });
    // var discounts = [];
    // _.forEach($('.nested-fields.discount-fields'), function ($discountElm) {
    //   discounts.push({
    //     title: $($discountElm).find('.js-discount-title').val(),
    //     amount: $($discountElm).find('.js-special-discount').val(),
    //   });
    // });
    // estimates['es']['discounts_attributes'] = discounts;

    // var estimateItems = [];
    // _.forEach($('.nested-fields.estimate-item-fields:not(:hidden)'), function ($esmItem) {
    //   var price = $($esmItem).find('.js-price').val();
    //   var sellPrice = $($esmItem).find('.js-sell_price').val();
    //   var esmItem = {
    //     product_text: $($esmItem).find('.js-product-text').val(),
    //     product_id: $($esmItem).find('.js-product-id').val(),
    //     amount: $($esmItem).find('.js-amount').val(),
    //     unit: $($esmItem).find('.js-unit').val(),
    //     cost_price: $($esmItem).find('.js-cost-price').val(),
    //     price: price == 0 ? '' : price,
    //     sell_price: sellPrice == 0 ? '' : sellPrice,
    //     note: $($esmItem).find('.js-note').val(),
    //     discount: $($esmItem).find('.js-discount').val(),
    //     is_ordered: $($esmItem).find('.form-check-input').is(':checked'),
    //     company_id: $($esmItem).find('.estimate-sheet-company-id').val(),

    //     // is_ordered: false,
    //     // company_id: '',
    //     discount: $($esmItem).find('.js-discount').val(),
    //     warehouse_quantity: $($esmItem).find('.js-warehouse_quantity').val(),
    //   };

    //   var orderSheets = [];
    //   _.forEach($($esmItem).find('.order-item-clone'), function ($orderItem) {
    //     var orderSheetItem = {};
    //     _.forEach(orderColumns, function (orderAttr) {
    //       var orderVal = $($orderItem)
    //         .find("[name$='[" + orderAttr + "]']")
    //         .val();
    //       orderSheetItem[orderAttr] = orderVal;
    //     });
    //     var isExistSheet = !_.every(orderSheetItem, function (val, key) {
    //       return _.isEmpty(val);
    //     });
    //     if (isExistSheet) {
    //       orderSheets.push(orderSheetItem);
    //     }
    //   });
    //   esmItem['estimate_item_orders_attributes'] = orderSheets;
    //   var isExistItem = !_.every(esmItem, function (val, key) {
    //     return _.isEmpty(val);
    //   });
    //   if (isExistItem) {
    //     esmItem['able_print'] = $($esmItem).find('.js-product-id').val();
    //     esmItem['estimate_item_type'] = $($esmItem).find('.estimate-item-type').val();
    //     estimateItems.push(esmItem);
    //   }
    // });
    // // eits = estimate_items_attributes for short name
    // estimates['es']['eits'] = estimateItems;
    // var expandPrams = $.param(estimates);
    // console.log(expandPrams.length);

    // $('#modal_add_new_estimate_sheet').modal('hide');
    // window
    //   .open(`/estimate_sheets/new?original_id=${originalId}&is_copy=true&${expandPrams}`, '_blank')
    //   .focus();
  });

  $('.js-submit-template').on('click', function () {
      var executionTemplateDetailIds = _.map(
        $('#template-item-content').find('.template-item-clone'),
        function ($elm) {
          return $($elm).attr('id');
        }
      );
      if (!_.isEmpty(executionTemplateDetailIds)) {
        $.ajax({
          url: `/execution_templates/load_execution_template_items?ids=${executionTemplateDetailIds}`,
          method: 'GET',
          success: function (res) {
            if (!_.isEmpty(res.templates)) {
              var templates = res.templates;
              _.forEach(templates, function (item) {
                $('.btn-add-execution').trigger('click');
              });
              setTimeout(function () {
                var templateLength = templates.length;
                var idx = 0;
                _.forEach(
                  _.reverse($('#form-estimate-nested-cost .estimate-item-fields:not(:hidden)')),
                  function ($elm) {
                    if (idx < templateLength) {
                      var currentTem = templates[idx];
                      _.forEach(
                        [
                          'product_id',
                          'amount',
                          'cost_price',
                          'note',
                          'price',
                          'unit',
                          'sell_price',
                          'discount',
                        ],
                        function (field) {
                          $($elm).find(`.js-${field}`).val(currentTem[field]).change();
                        }
                      );
                    }
                    idx = idx + 1;
                  }
                );
              }, 500);
            }
          },
        });
      }
    });

  if ($('.edit-estimate-sheet').length > 0 || $('.page-copy').length > 0) {
    estimateSheetCalcTotal(false);
  }

  $('div[data-est-sheet-id]').on('click', function () {
    const id = this.getAttribute('data-est-sheet-id');
    $('body').loading({
      message: '書類の取得...',
    });

    $.ajax({
      method: 'PUT',
      url: `/estimate_sheets/${id}/update_printed`,
    });
    const url = this.getAttribute('data-pdf-path').replace(/"/g, '');
    $.ajax({
      url: `${url}`,
      type: 'GET',
      xhrFields: {
        responseType: 'blob' // Đảm bảo nhận dữ liệu dưới dạng blob
      },
      success: function(blob) {
        var url = window.URL.createObjectURL(blob); 
        var iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);

        iframe.onload = function() {
          iframe.contentWindow.focus();
          iframe.contentWindow.print();
          $('body').loading('destroy');
        };
      },
      error: function(error) {
        console.error('Error:', error);
        $('body').loading('destroy');
      }
    });
  });

  $('span[data-est-sheet-id]').on('click', function () {
    $('body').loading({
      message: '書類の取得...',
    });
    var id = this.getAttribute('data-est-sheet-id');
    $.ajax({
      method: 'PUT',
      url: `/estimate_sheets/${id}/update_printed`,
    });
    setTimeout(() => {
      $('body').loading('destroy');
    }, 3000);
  });

  $('.arrow-down-main').on('click', function () {
    $('.table-form-estimate-sheet').find('tr:not(.ignore-hide)').removeClass('d-none');
    $('.arrow-up-main').removeClass('d-none');
    $(this).addClass('d-none');
  });

  $('.arrow-up-main').on('click', function () {
    $('.table-form-estimate-sheet').find('tr:not(.ignore-hide)').addClass('d-none');
    $('.arrow-down-main').removeClass('d-none');
    $(this).addClass('d-none');
  });

  $(document).on('click', '.toggle-up', function () {
    var $closestParent = $(this).closest('.nested-fields');
    $closestParent.find('.toggle-down').removeClass('d-none');
    $closestParent.find('.multi-collapse').removeClass('show');
    $(this).addClass('d-none');
  });

  $(document).on('click', '.arrow-up', function () {
    var $closestParent = $(this).closest('.nested-fields');
    $closestParent.find('.arrow-down').removeClass('d-none');
    $closestParent.find('.wrap-expand-arrow').addClass('d-none');
    $(this).addClass('d-none');
  });

  $(document).on('click', '.toggle-down', function () {
    var $closestParent = $(this).closest('.nested-fields');
    $closestParent.find('.toggle-up').removeClass('d-none');
    $closestParent.find('.multi-collapse').addClass('show');
    $(this).addClass('d-none');
  });

  $(document).on('click', '.arrow-down', function () {
    var $closestParent = $(this).closest('.nested-fields');
    $closestParent.find('.arrow-up').removeClass('d-none');
    $closestParent.find('.wrap-expand-arrow').removeClass('d-none');
    $(this).addClass('d-none');
  });

  $('.esimate-sheet-page').on('cocoon:after-insert', function (e, insertItem) {
  });

  $(document).on('cocoon:after-insert', '.partner-list', function (e, insertedItem, originalEvent) {
  });

  $('.cal-estimate-price').on('click', function () {
    if ($("#estimate_sheet_is_final").attr('disabled') == 'disabled') {
      return false
    }
    $('#modal_confirm_calc').modal('show');
  });

  $('.new-estimate-sheet').on('click', function (e) {
    var id = $(this).data("item-id") 
    if ($("#estimate_sheet_is_final").attr('disabled') == 'disabled' || $(e.target).attr('disabled') == 'disabled') {
      return false
    }

    $('#modal_add_new_estimate_sheet').modal('show');
    $('#button_copy_estimate_sheet').data( "id", id);
  });

  $('#add_new_estimate_sheet').on('click', function () {
    var root = $(this).closest('a');
    var href = $(root)[0].href;
    window.open(href, '_blank').focus();
  });

  function show_warehouse() {
    $('.js-product-id').on('change', function (e) {
      var product_type = $(this).attr('product_type');
      var parent_nested_field = $(this).closest('.nested-fields');
      if (product_type === 'object') {
        parent_nested_field.find('.show-warehouse').removeClass('hidden');
      } else {
        parent_nested_field.find('.show-warehouse').addClass('hidden');
      }
    });

    $('.show-stocks').on('click', function () {
      var parent_nested_field = $(this).closest('.nested-fields');
      var product_type = parent_nested_field.find('.js-product-id').attr('product_type');
      if (product_type === 'object') {
        parent_nested_field.find('.show-warehouse').removeClass('hidden');
      } else {
        parent_nested_field.find('.show-warehouse').addClass('hidden');
      }
    });
  }

  $(document).on('click', '#check_all_est', function (event) {
    $('.check-est-item').prop('checked', $(this).prop('checked'));
  });

  $('#approve_all_est').on('click', function () {
    var checkedValues = $('.check-est-item:checkbox:checked')
      .map(function () {
        return this.name;
      })
      .get();

    $.ajax({
      url: '/estimate_sheets/approve_all_est',
      method: 'PUT',
      data: { estimate_sheet_ids: checkedValues },
      success: function (res) {
        window.location = '/estimate_sheets';
      },
    });
  });

  var isFormProject = $('.esimate-sheet-page').data('is-from-project');
  if (isFormProject || isCopyPage) {
    setTimeout(function () {
      $('.estimate-sheet-project-id').trigger('change');
    }, 200);
  }

  // $('.estimate-sheet-project-id').on('change', function () {
  //   var projectId = $(this).val();

  //   if (projectId) {
  //     $('.estimate-sheet-acceptor-ids')
  //       .html('')
  //       .select2({ data: [{ id: '', text: '' }] });
  //     $.ajax({
  //       url: '/estimate_sheets/load_workers',
  //       method: 'GET',
  //       data: { project_id: projectId },
  //       success: function (res) {
  //         var managers = _.map(res.managers, function (worker) {
  //           return { id: worker.id, text: worker.fullname };
  //         });
  //         $('.estimate-sheet-acceptor-ids').html('').select2({ data: managers });
  //         $('.estimate-sheet-acceptor-ids').prop('disabled', false);
  //         resetSelect2($('.estimate-sheet-acceptor-ids'));
  //         if(res.company_id != undefined) {
  //           $('.estimate-sheet-company-id').val(res.company_id).trigger('change');
  //         }
  //         $('.estimate-sheet-acceptor-ids').trigger('change');
  //         if (isCopyPage) {
  //           $('.estimate-sheet-worker-ids').val($('.wrap-assign-worker-ids').data("ids"));
  //           $('.estimate-sheet-worker-ids').trigger('change');
  //           $('.estimate-sheet-company-id').val($('.wrap-assign-company-id').data("id"));
  //           $('.estimate-sheet-company-id').trigger('change');
  //         }
  //       },
  //     });
  //   } else {
  //     $('.estimate-sheet-worker-ids').prop('disabled', true);
  //   }
  // });
  $('.estimate-sheet-project-id').on('change', function () {
    var nameProject = $(this).find("option:selected").text();
    $("#estimate_sheet_name").val(nameProject);
    var project_id = $(this).val();
    if(project_id) {
      $.ajax({
        method: 'get',
        url: `/projects/${project_id}/check_is_final`,
        success: function(e) {
          if(e.is_final == true) {            
            $('#estimate_sheet_is_final').attr('disabled', true);
          } else {            ;
            $('#estimate_sheet_is_final').attr('disabled', false);
          }
        }
      })
    }    
  });

  function resetSelect2($elm) {
    $($elm).select2({
      theme: 'bootstrap',
      placeholder: '選択してください',
      allowClear: true,
      language: {
        noResults: function () {
          return '見つかりません。';
        },
      },
    });
  }

  $(document).on('submit', '.form-estimate-sheet', function (e) {
    e.preventDefault();
    // var marginNumber = _.toNumber($('.margin-number-temp').val()) || 0;
    Array.from(['.js-amount', '.js-cost-price', '.js-price', '.js-sell_price']).forEach((id) => {
      $(id)
        .toArray()
        .forEach(function (field) {
          // const cleave = new Cleave(field, {
          //   numeral: true,
          //   numeralThousandsGroupStyle: 'thousand',
          // });
          // const val = cleave.getRawValue();
          // $(field).val(val);
        });
    });
    e.currentTarget.submit();
  });
});

$(document).on('click', '.btn-open-search-product', function (evt) {
  // $('.select-search-product').val('').trigger('change');
  $('.select-search-product').val('')
  var nameSearching = $(evt.target).closest('div').find('.js-product-text').val()
  $('#modal-search-product #q_companies_name_cont').val('');
  $('#modal-search-product #q_name_cont').val('');
  $('#modal-search-product #q_price_gteq').val('');
  $('#modal-search-product #q_price_lteq').val('');
  // $('#modal-search-product #q_name_cont').val(nameSearching);
  $('.btn-submit.search-product').click();
  var nestedField = $(evt.target).closest('.nested-fields');
  var insertingSelector = nestedField.length > 0 ? { type: 'estimateSheet', value: $(evt.target).closest('.nested-fields') } : { type: 'acceptingOrder', value: $(evt.target).closest('form').attr('id') }
  window.insertingSelector = insertingSelector
  $('#modal-search-product').modal('show');
});



$(document).on('click', '.checkbox-estimate-item', function () {
  var isChecked = $(this).is(':checked');
  const checkboxes = $('.checkbox-estimate-item');
  if (!isChecked) {
    $(this)
      .closest('.partner-list')
      .find('.toggle-check-all-object')
      .prop('checked', isChecked);
  } else {
    const allChecked = checkboxes.length === checkboxes.filter(':checked').length;
    $(this).closest('.partner-list').find('.toggle-check-all-object').prop('checked', allChecked);
  }

  if (!isChecked) {
    $(this)
      .closest('.partner-list')
      .find('.toggle-check-all-execution')
      .prop('checked', isChecked);
  } else {
    var checkboxEstimateItems = $(this)
      .closest('.partner-list')
      .find('.checkbox-estimate-item')
      .slice(1);
    var elements = [];
    _.forEach(checkboxEstimateItems, function (item) {
      var checked = $(item).is(':checked');
      if (checked) {
        elements.push(item);
      }
    });
    if (checkboxEstimateItems.length == elements.length) {
      $(this)
        .closest('.partner-list')
        .find('.toggle-check-all-execution')
        .prop('checked', true);
    }
  }
});

$(document).on('click', '.js-submit-template', function () {
  var executionTemplateDetailIds = _.map(
    $('#template-item-content').find('.template-item-clone'),
    function ($elm) {
      return $($elm).attr('id');
    }
  );
  if (!_.isEmpty(executionTemplateDetailIds)) {
    $.ajax({
      url: `/execution_templates/load_execution_template_items?ids=${executionTemplateDetailIds}`,
      method: 'GET',
      success: function (res) {
        if (!_.isEmpty(res.templates)) {
          var templates = res.templates;
          _.forEach(templates, function (item) {
            $('.btn-add-execution').trigger('click');
          });
          setTimeout(function () {
            var templateLength = templates.length;
            var idx = 0;
            _.forEach(
              _.reverse($('#form-estimate-nested-cost .estimate-item-fields:not(:hidden)')),
              function ($elm) {
                if (idx < templateLength) {
                  var currentTem = templates[idx];
                  _.forEach(
                    [
                      'product_id',
                      'amount',
                      'cost_price',
                      'note',
                      'price',
                      'unit',
                      'sell_price',
                      'discount',
                    ],
                    function (field) {
                      $($elm).find(`.js-${field}`).val(currentTem[field]).change();
                    }
                  );
                }
                idx = idx + 1;
              }
            );
          }, 500);
        }
      },
    });
  }
});

$(document).on('click', '#confirm_calc', function () {
  var marginNumber = _.toNumber($('.margin-number-temp').val()) || 0;
  const id = $('.esimate-sheet-page')[0].getAttribute('data-esti-sheet-id');
  if (id) {
    $.ajax({
      method: 'PUT',
      url: `/estimate_sheets/${id}/update_margin_number`,
      data: { margin_number: marginNumber },
    });
  }
  estimateSheetCalcTotal(true);
});

$(document).on('click', '.calc_discount', function () {
  calcDiscount();
});

$(document).on('change', 'form.form-estimate-sheet ', function (e) {
  $('.new-estimate-sheet').addClass('opacity-50');
  $('.new-estimate-sheet').attr('disabled', 'disabled');
})

$(document).on('click', '.js-choose-template', function () {
  var templates = _.reverse($(this).closest('.row').data('values'));

  _.forEach(templates, function (item) {
    $('.js-add-execution').trigger('click');
    _.times(1, function (idx) {
      var isCheckedAllE = $('.toggle-check-all-execution').is(':checked');
      $('.toggle-check-all-execution')
        .closest('.partner-list')
        .find('.checkbox-estimate-item')
        .prop('checked', isCheckedAllE);
    });
  });

  setTimeout(function () {
    templates = templates.sort(function(a, b) {
                  var keyA = a.sort_index,
                    keyB = b.sort_index;
                  if (keyA < keyB) return -1;
                  if (keyA > keyB) return 1;
                  return 0;
                });
    var templateLength = templates.length;
    var idx = 0;
    var countFieldEmpty = $("#form-estimate-nested-cost .estimate-item-fields:not(:hidden)").length;
    _.forEach($("#form-estimate-nested-cost .estimate-item-fields:not(:hidden)"), function ($elm) {
      var checkEmpty = true
      Array.from($elm.getElementsByClassName('form-control')).forEach((el) => {
        if(el && el.value){
          checkEmpty = false;
        }
      });
      if (!checkEmpty){
        countFieldEmpty = countFieldEmpty - 1;
      }
    })

    if(templateLength > countFieldEmpty){
      for(var i = 0; i < templateLength - countFieldEmpty; i++) {
        $( ".js-add-estimate-execution" ).trigger( "click" );
      }
    }
    _.forEach($("#form-estimate-nested-cost .estimate-item-fields:not(:hidden)"), function ($elm) {
      var checkEmpty = true
      Array.from($elm.getElementsByClassName('form-control')).forEach((el) => {
        if(el && el.value){
          checkEmpty = false;
        }
      });
      if(!checkEmpty){
        return true;
      }
      if (idx < templateLength) {
        var currentTem = templates[idx];
        _.forEach(["name", "amount", "cost_price", "note", "price", "unit", "sell_price", "discount sell_price"], function (field) {
          $($elm).find(`.js-${field}`).val((currentTem[field] != undefined ? currentTem[field].toLocaleString() : '')).change()
        });
      }
      idx = idx + 1;
    })
  }, 500);
});

$(document).ready(function() {
  $(document).on('change', '.estimate-sheet-company-id', function() {
    var input_name = $(this).attr('name').split('[').slice(0, 3).join('[');
    var selectedCompanies = $(this).val();
    var orderDetailsTable = $(this).closest('.ordered-item-area').find('.order-details-table');
    var orderDetailsBody = $(this).closest('.ordered-item-area').find('.order-details-body');

    if (selectedCompanies && selectedCompanies.length > 0) {
      orderDetailsTable.removeClass('hidden');

      orderDetailsBody.find('tr').each(function() {
        var companyId = $(this).find('input[name*="[company_id]"]').val();
        var itemIndex = $(this).find('input[name*="[company_id]"]').attr('name').match(/\[(\d+)\]/)[1];
        var nestedIndex = $(this).find('input[name*="[company_id]"]').attr('name').match(/\[(\d+)\]/g)[1].replace(/\D/g, '');
        
        if (selectedCompanies.indexOf(companyId) === -1) {
          if (!$(this).find('input[name*="[_destroy]"]').length) {
            $(this).append('<input type="hidden" name="' + input_name + '[estimate_item_companies_attributes][' + nestedIndex + '][_destroy]" value="true">');
          }
          $(this).addClass('hidden');
        } else {
          $(this).removeClass('hidden');
        }
      });

      $(this).find("option:selected").each(function () {
        var $this = $(this);
        var companyName = $this.text();
        var companyId = $this.val();
        var currentTimeMillis = new Date().getTime();

        var existingRow = orderDetailsBody.find('input[value="' + companyId + '"]');
        if (existingRow.length === 0 && companyName != '') {
          var row = `
            <tr id="company_id_${companyId}">
              <td>${companyName}</td>
              <td><input type="text" name="${input_name}[estimate_item_companies_attributes][${currentTimeMillis}][company_id]" value="${companyId}" class="hidden">
                <input type="text" name="${input_name}[estimate_item_companies_attributes][${currentTimeMillis}][quantity]" class="convert-half-width form-control text-right amount-pro est-price-partner"></td>
              <td><input type="text" name="${input_name}[estimate_item_companies_attributes][${currentTimeMillis}][price]" class="convert-half-width form-control text-right est-price-partner price-pro"></td>
            </tr>
          `;
          orderDetailsBody.append(row);
        }
        sum_cost_price($(this));
      });
    } else {
      orderDetailsTable.addClass('hidden');
      orderDetailsBody.html('');
      sum_cost_price($(this));
    }
  });
});

$(document).on('change', '.est-price-partner', function() {
  sum_cost_price($(this));
})

function sum_cost_price(ele) {
  var total_price = 0;
  var has_value = false;
  var total_quantity = 0;
  ele.closest('.nested-fields').find('tr:not(.hidden)').find('.price-pro').each(function() {
    if($(this).val() != '') {
      has_value = true;
    }
    var value = parseInt($(this).val().replace(/,/g, '')) || 0;
    var amount = parseInt($(this).closest('tr').find('.amount-pro').val().replace(/,/g, '')) || 0;
    total_quantity += amount;
    total_price += (value * amount) || 0;
  });
  if(has_value) {    
    var value_string = Math.round(total_price/total_quantity);
    if(isNaN(value_string)) {
      ele.closest('.nested-fields').find('.js-cost-price').val(0);
    } else {
      ele.closest('.nested-fields').find('.js-cost-price').val(value_string.toLocaleString() || 0);
    }
  } else {
    ele.closest('.nested-fields').find('.js-cost-price').val(0);
  }
}

$(document).on('change', '.est-checked-ordered', function() {
  if($(this).is(':checked')) {    
    $('#old-cost-price').val($(this).closest('.nested-fields').find('.js-cost-price').val());
    $(this).closest('.nested-fields').find('.js-cost-price').attr('readonly', true).addClass('bg-gray');
    sum_cost_price($(this));
  } else {    
    $(this).closest('.nested-fields').find('.js-cost-price').attr('readonly', false).val($('#old-cost-price').val()).removeClass('bg-gray');
  }
})

window.estimateSheetCalcTotal = estimateSheetCalcTotal
