const { prototype } = require("sortablejs");

$(function () {
  if (!$('#project_billing_start_month').closest('.input-clearable').find('.clear-icon').is(':visible')) {
    $('.project-form').val('');
  }
  var url = new URL(window.location.href);
  var searchParams = new URLSearchParams(url.search);
  if (!searchParams.has('current_tab') || searchParams.get('current_tab').trim() === '') {
    $('.edit-project-form .wrap-tab-pane li').first().find('a').tab('show')
  }

  $('#project_contract_start_at').on('apply.daterangepicker', function (ev, picker) {
    let format = $(this).data('format') || 'YYYY/MM/DD';
    var value = picker.startDate.format(format);
    $("#project_billing_start_month").val(picker.startDate.format('YYYY/MM')).trigger('change');
    $('#project_billing_start_month').data('daterangepicker').setStartDate(value);


    $(this).val(value);
    $('#project_contract_end_at').daterangepicker({
      locale: ja_date_locale,
      singleDatePicker: true,
      autoUpdateInput: false,
      autoApply: true,
      startDate: value,
      showDropdowns: false,
      minViewMode: 'months',
      minDate: value,
    });
    $('#project_contract_end_at').val('')
    $('#project_contract_end_at').on('apply.daterangepicker', function (ev, picker) {
      let format = $(this).data('format') || 'YYYY/MM/DD';
      $(this).val(picker.startDate.format(format));
      $('#project_contract_end_at').closest('.input-clearable').find('.clear-icon').css('display', 'inline');
    });
    $('#project_billing_start_month').closest('.input-clearable').find('.clear-icon').css('display', 'inline');
  });

  $('#project_contract_end_at').on('apply.daterangepicker', function (ev, picker) {
    let format = $(this).data('format') || 'YYYY/MM/DD';
    $(this).val(picker.startDate.format(format));
    $('#project_contract_end_at').closest('.input-clearable').find('.clear-icon').css('display', 'inline');
  });

  if ($('.edit-project-form').length > 0) {
    const PROJECT_ATTRS = [
      // 'name',
      // 'status',
      // 'note',
      // 'project_project_type_continue',
      // 'parent_project_id',
      // 'contracted_at',
      // 'company_id',
      // 'start_at',
      // 'department_id',
      // 'estimated_deliver_at',
      // 'completed_at',
      // 'deliver_at',
      // 'assigned_worker_ids',
      // 'transaction_status',
      // 'sending_place_id',
      // 'contract_start_at',
      // 'contract_end_at',
      // 'project_payment_period_monthly',
      // 'payment_type',
      // 'contact_location',
      // 'project_info',
    ];
    const PROJECT_ID = $('.table-plan-result').data('project-id');
    let $settingPlanModal = $('#setting-plan-project');
    let $planForm = $('.form-create-edit-plan');
    let $confirmDestroyPlanModal = $('#confirm-destroy-plan');

    var workerIds = $(".form-show-project").data("worker-ids");
    if (workerIds) {
      $(".project-page.assign-worker-id").val(workerIds).trigger("change")
    }

    toggleForm(true);

    $('.btn-edit-project').on('click', function () {
      $(this).addClass('d-none');
      $('.btn-save-project').removeClass('d-none');
      toggleForm(false);
    });

    $(".form-show-project").find("tr").addClass("setting-height");

    function toggleForm(ediableForm) {
      _.each(PROJECT_ATTRS, function (attr) {
        if (ediableForm) {
          $(`#${attr}`).closest('.form-group').addClass('d-none');
          $('#is_parent').prop('disabled', true);
          $(`.display-${attr}`).removeClass('d-none');
        } else {
          $(`#${attr}`).closest('.form-group').removeClass('d-none');
          $(`.display-${attr}`).addClass('d-none');
          $('#is_parent').prop('disabled', false);
        }
      });
      if (ediableForm) {
        $(".form-show-project").find("tr").addClass("setting-height");
      } else {
        $(".form-show-project").find("tr").removeClass("setting-height");
      }
    }

    $('.setting-plan').on('click', function () {
      $settingPlanModal.find('.title').text('予定登録');
      $settingPlanModal.find('.submit-plan-text').text('登録');
      $planForm.attr('method', 'POST');
      $settingPlanModal.attr('project_account_plan_id', '');
      $settingPlanModal.modal('show');
    });

    $('.js-save-account-plan').on('click', function () {
      resetPlanForm();
      let projectPlanId = $settingPlanModal.attr('project_account_plan_id');
      let isEdit = !!projectPlanId;
      let planFormData = $planForm.serialize();
      let url = $planForm.attr('action');
      if (isEdit) {
        url = `/projects/${PROJECT_ID}/project_account_plans/${projectPlanId}`;
      }

      $.ajax({
        url: url,
        method: $planForm.attr('method'),
        data: planFormData,
        success: function (res) {
          if (res.status) {
            window.location.href = `/projects/${PROJECT_ID}/edit`;
          } else {
            let errors = res.errors;
            _.each(errors, function (plan_errors, attr) {
              var $inputElement = $planForm.find("[name$='[" + attr + "]']");
              if (attr == 'account_type') {
                let $wrapElm = $inputElement.closest('.wrap-account-type');
                let $select2Elm = $wrapElm.find('.select2-container--bootstrap');
                $select2Elm.find('.select2-selection--single').addClass('has-error');
                $(`<span class='help-block'>${plan_errors[0]}</span>`).insertAfter($select2Elm);
              } else {
                $(`<span class='help-block'>${plan_errors[0]}</span>`).insertAfter($inputElement);
                $inputElement.addClass('has-error');
              }
            });
          }
        },
      });
    });

    function resetPlanForm() {
      let $planForm = $('.form-create-edit-plan');
      $planForm.find('.has-error').removeClass('has-error');
      $planForm.find('.help-block').remove();
    }

    $('.edit-plan').on('click', function () {
      let planId = $(this).closest('.wrap-control-plan-item').data('plan-id');
      $settingPlanModal.find('.title').text('予定編集');
      $settingPlanModal.find('.submit-plan-text').text('変更');
      $settingPlanModal.attr('project_account_plan_id', planId);
      $planForm.attr('method', 'PUT');

      $.ajax({
        url: `/projects/${PROJECT_ID}/project_account_plans/${planId}/load_plan_object`,
        method: 'GET',
        success: function (res) {
          let planData = res.project_account_plan;
          _.each(['account_type', 'account_at_format', 'amount'], function (field) {
            $(`#plan_${field}`).val(planData[field]);
          });
          reInitElm();
          $settingPlanModal.modal('show');
        },
      });
    });

    $('.destroy-plan').on('click', function () {
      let planId = $(this).closest('.wrap-control-plan-item').data('plan-id');
      $confirmDestroyPlanModal.attr('plan-id', planId);
      $confirmDestroyPlanModal.modal('show');
    });

    $('.js-confirm-destroy-plan').on('click', function () {
      let projectPlanId = $confirmDestroyPlanModal.attr('plan-id');

      $.ajax({
        url: `/projects/${PROJECT_ID}/project_account_plans/${projectPlanId}`,
        method: 'DELETE',
        success: function (res) {
          if (res.status) {
            window.location.href = `/projects/${PROJECT_ID}/edit`;
          }
        },
      });
    });

    function reInitElm() {
      $('#plan_account_type').select2({
        theme: 'bootstrap',
        placeholder: '選択してください',
        allowClear: true,
        language: {
          noResults: function () {
            return '見つかりません。';
          },
        },
      });
    }

    $('.js-search-plan').on('click', function () {
      let year = $('.input-year').val();
      let month = $('.input-month').val();
      if (
        !year ||
        !month ||
        _.toString(year).length != 4 ||
        _.toInteger(month) > 12 ||
        _.toInteger(month) < 1
      )
        return;

      let params = {
        search_month: `${year}/${month}/01`,
        is_search: true,
        year: year,
        month: month,
      };
      searchPlan(params);
    });

    $('.js-jump-to-plan').on('click', function () {
      var dataMonth = $(this).data('month');
      var currentMonth = $('.table-plan-result').data('current-month');

      let params = {
        current_month: currentMonth,
        is_search: true,
        step_month: _.toInteger(dataMonth),
      };
      searchPlan(params);
    });

    function searchPlan(params) {
      $.ajax({
        url: `/projects/${PROJECT_ID}/edit`,
        method: 'GET',
        contentType: 'application/json',
        dataType: 'json',
        data: params,
        success: function (res) {
          let searchParams = new URLSearchParams(res.search_params);
          window.location.href = `/projects/${PROJECT_ID}/edit?${searchParams.toString()}`;
        },
      });
    }

    $('#plan_amount').on('keydown', function (e) {
      let invalidChars = ["e"]
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });

    $('.btn-custom-cancel').on('click', function () {
      $('#plan_account_type').val('').trigger('change');
      $('.form-create-edit-plan')[0].reset();
      $('.help-block').empty();
      $('.form-control').removeClass('has-error');
    });
  }

  $(".sending-company-id").on("change", function() {
    var company_id = $(this).val();
    $.ajax({
      url: `/companies/${company_id}/load_sending_place`,
      method: 'get',
      success: function (res) {
        // $('#sending_place_id').find('option').remove();
        if ($("#project_project_type").val() == 'korabo' || $("input[name='project[project_type]']:checked").val() == 'korabo') {
          $('#project_contact_location').val(res.address);
        }
        // $.each(res.companies, function (i, item) {
        //   debugger
        //   $('#sending_place_id').append($('<option>', {
        //     value: item[1],
        //     text : item[0]
        //   }));
        //   $('#sending_place_id').attr('disabled', false);
        //   $('#payment_type').val(res.payment_type).change();
        // });
        $('#payment_type').val(res.payment_type).change();
        if(res.companies != null){
          $('#sending_place_id').val(res.companies.id).change()
        }
      }
    })
  })

  if($('.datepicker').length > 0){
    $('.datepicker').datepicker({
      changeMonth: true,
      changeYear: true,
      showButtonPanel: false,
      dateFormat: 'mm/yy',
      onClose: function(dateText, inst) {
          var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
          var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
          $(this).datepicker('setDate', new Date(year, month, 1));
      }
    });
  }
});

function loadWorkers(departmentId) {
  $.ajax({
    url: "/projects/load_workers",
    method: "GET",
    data: { department_id: departmentId },
    success: function (res) {
      var workers = _.map(res.workers, function (worker) {
        return { id: worker.id, text: worker.fullname };
      })
      $(".project-page.assign-worker-id").html('').select2({ data: workers });
      $(".project-page.assign-worker-id").prop("disabled", false);
      $(".project-page.assign-worker-id").select2({
        theme: 'bootstrap',
        placeholder: '選択してください',
        allowClear: true,
        language: {
          noResults: function () {
            return '見つかりません。';
          },
        },
      });

      $("#assigned_worker_ids").html('').select2({ data: workers });
      $("#assigned_worker_ids").prop("disabled", false);
      $("#assigned_worker_ids").select2({
        theme: 'bootstrap',
        placeholder: '選択してください',
        allowClear: true,
        language: {
          noResults: function () {
            return '見つかりません。';
          },
        },
      });
    },
  });
}

$(document).on('change', ".project-page.department-id", function () {
  var departmentId = $(this).val();
  $(".project-page.assign-worker-id").html('').select2({ data: [{ id: '', text: '' }] });
  if (departmentId) {
    loadWorkers(departmentId)
  } else {
    $(".project-page.assign-worker-id").prop("disabled", true);
  }
})


$(document).on('change', '#modal-create-bill-sheet #bill_sheet_status ', function () {
  var value = $(this).val();
  var notSendId = $("#bill_sheet_bill_no").data('not-send-id').toString()
  var editNotSend = notSendId.length > 0 && $('#bill_sheet_id').val() == notSendId
  var projectType = $("#project_project_type").val()

  if (!editNotSend && projectType != 'continue' && value == 'not_send' && (projectType == 'spot' || projectType == 'korabo')) {
    $('#modal-create-bill-sheet #error-message').removeClass('d-none');
    $('#modal-bill-sheet-submit').prop('disabled', true);
  } else {
    $('#modal-create-bill-sheet #error-message').addClass('d-none');
    $('#modal-bill-sheet-submit').prop('disabled', false);
  }
})

$(document).on('change', '#modal-create-bill-sheet #bill_sheet_pay_method ', function () {
  var value = $(this).val();
  let now = new Date();
  let formattedDate;
  if (value == 'transfer_deposit') {
    let endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    // if (now.getDate() === endOfMonth.getDate()) {
    //   endOfMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0);
    // }
    endOfMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0);
    formattedDate = endOfMonth.getFullYear() + '/' + (endOfMonth.getMonth() + 1).toString().padStart(2, '0') + '/' + endOfMonth.getDate().toString().padStart(2, '0');
  } else if (value == 'direct_debit') {
    nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 27);
    formattedDate = nextMonth.getFullYear() + '/' + (nextMonth.getMonth() + 1).toString().padStart(2, '0') + '/' + nextMonth.getDate().toString().padStart(2, '0');
  } else {
    if ($("#bill_sheet_expired_at").length > 0) {
      formattedDate = $("#bill_sheet_expired_at").val()
    } else {
      formattedDate = $("#get_deposit_date_id").val()
    }

  }
  $('#modal-create-bill-sheet #bill_sheet_deposit_date').val(formattedDate)
})

function showFieldByProjectType(projectType = undefined) {
  projectType = projectType || $("input[name='project[project_type]']:checked").val()

  if (projectType == 'spot') {
    $('.is-korabo').addClass('d-none');
    $('.not-korabo').removeClass('d-none');
    $('.is-continue').addClass('d-none');
    $('.is_parent_container').removeClass('d-none');
    $('.pj_name_container .project_name').removeClass('w-100');
    $('#note_spot').prop('name', 'project[note]');
    $('#note_continue').prop('name', '');
    $('.not-spot').addClass('d-none');
    $('[data-cell-multi-fields]').removeClass('cell-multi-fields');
    $('.is-spot').removeClass('d-none');
    $('.new_project #name').val('');
    // if ($('form.edit_project').length == 0) {
    //   $('#status').val('not_start').change();
    // }
    $('#status').prop('disabled', false);
    if ($('.new_project').length > 0 && $('#department_id').val().length > 0) {
      loadWorkers($('#department_id').val())
    }
  } else if (projectType == 'continue') {
    $('.is-korabo').addClass('d-none');
    $('.not-korabo').removeClass('d-none');
    // $('.not-spot.is-korabo').removeClass('d-none');
    $('.is_parent_container').removeClass('d-none');
    $('.pj_name_container .project_name').removeClass('w-100');
    $('#note_continue').prop('name', 'project[note]');
    $('#note_spot').prop('name', '');
    $('[data-cell-multi-fields]').addClass('cell-multi-fields');
    $('.is-spot').addClass('d-none');
    $('.not-spot').removeClass('d-none');
    $('.is-continue').removeClass('d-none');
    $('.new_project #name').val('');
    // $('#status').val('inprogress').change();
    if ($('.new_project').length > 0 && $('#department_id').val().length > 0) {
      loadWorkers($('#department_id').val())
    }
  } else if (projectType == 'korabo') {
    $('.new_project #name').val('ミライク光');
    $('.is-continue').addClass('d-none');
    $('.is-spot').addClass('d-none');
    // $('.not-spot').addClass('d-none');
    $('#note_continue').prop('name', 'project[note]');
    $('#note_spot').prop('name', '');
    $('.not-korabo').addClass('d-none');
    $('.is_parent_container').addClass('d-none');
    $('.pj_name_container .project_name').addClass('w-100');
    $('.is-korabo').removeClass('d-none');
    $('[data-cell-multi-fields]').removeClass('cell-multi-fields');
    // $('#status').val('inprogress').change();
    $('#status').prop('disabled', false);
    if ($('.new_project').length > 0) {
      loadWorkers()
    }
  }
}

$(document).on('change', "input[name='project[project_type]']", function() {
  var projectType = $("input[name='project[project_type]']:checked").val()
  var newOptions = {}
  if (projectType == 'korabo') {
    newOptions = {
      "inprogress": "受注・推進中",
      "not_yet_billing": "未請求",
      "billing": "請求",
      "billing_done": "請求済",
      "money_received": "入金",
      "done": "完了",
      "lost_order": "失注",
    };
  } else {
    newOptions = {
      "not_start": "未着手",
      "inprogress": "受注・推進中",
      "payment_request": "請求",
      "money_received": "入金",
      "billing": "請求",
      "completion": "完了",
      "not_yet_billing": "未請求",
      "a_part_billing": "一部請求済・請求済",
      "billing_done": "請求済",
      "done": "完了",
      "lost_order": "失注",
    };
  }

  if(projectType == 'spot') {
    $('#transaction-continue').addClass('d-none');
    $('#transaction-continue').find('input').attr('disabled', true);
    $('#transaction-spot').removeClass('d-none');
    $('#transaction-spot').find('input').attr('disabled', false);
    $('#transaction-spot').find('input[value=cash]').prop('checked', true).trigger('change');
  } else {
    $('#transaction-spot').addClass('d-none');
    $('#transaction-spot').find('input').attr('disabled', true);
    $('#transaction-continue').removeClass('d-none');
    $('#transaction-continue').find('input').attr('disabled', false).removeClass('disabled');
    $('#transaction-continue').find('div').removeClass('disabled');
    $('#transaction-continue').find('input').prop('checked', true).trigger('change');
  }

  $('.project_status_korabo').children().remove()
  var select = $('.project_status_korabo');
  $.each(newOptions, function(text, key) {
      var option = new Option(key, text);
      select.append($(option));
  });

  showFieldByProjectType()
})

// TODO run befor show page
$(function () {
  if ($('.form-project').length > 0) {
    projectType = $('#project_project_type').val()
    showFieldByProjectType(projectType)
  }
})

$(document).on('change', "[name='project[payment_period]']", function() {
  if ($(this).val() == 'monthly') {
    $('#project_payment_period_months').prop('disabled', true).trigger('change.select2');
  } else {
    $('#project_payment_period_months').prop('disabled', false).trigger('change.select2');
    $('#project_payment_period_months').val('').trigger('change.select2');
  }
})

$(document).on('change', "#contract_start_at", function () {
  var value = $(this).val();
  $("#billing_start_month").val(value);
})

$(document).on('click', ".js-modal-bill-sheet-month", function () {
  $('#modal-choose-bill-sheet-month').modal("show");
})

$(document).on('click', "#choose-bill-sheet-month-submit", function () {
  var url = $('.new-bill-with-month').attr('href');
  url = url + '&bill_sheet_month=' + $('#bill_sheet_month').val()
  $('.new-bill-with-month').attr('href', url);
})

$(document).on('click', "#modal-choose-bill-sheet-month .clear-icon", function () {
  var url = $('.new-bill-with-month').attr('href');
  url = url + '&bill_sheet_month='
  $('.new-bill-with-month').attr('href', url);
  $('#choose-bill-sheet-month-submit').prop('disabled', this.value ? false : true);
})

$(document).on('click', "#confirm-bill-sheet-submit", function () {
  $('#modal-bill-sheet-submit').click();
})

$(document).on('hide.bs.modal', '#modal-choose-bill-sheet-month', function() {
  var currentdate = new Date()
  var year = currentdate.getFullYear()
  var month = currentdate.getMonth() + 1
  $(this).find('.error-message').addClass("hidden");
  $('#modal-choose-bill-sheet-month input.singledate-month-year').data('daterangepicker').setStartDate(year + '/' + month);
})

const ele_singledate = () => {
  const ele = $('.project_deliver_at .singledate');
  const ele_project_completed_at = $('.project_completed_at .singledate');

  if ($('#is-parent').is(':checked')) {
    ele.prop('disabled', true)
    ele_project_completed_at.prop('disabled', true)
  } else {
    ele.prop('disabled', false)
    ele_project_completed_at.prop('disabled', false)
  }
}

$(document).on('change', "#is-parent", function() {
  ele_singledate()
  const project_id = $(this).data('project-id');
  $.ajax({
    url: `/projects/${project_id}/update_is_parent`,
    type: 'PUT',
    data: { is_parent: $(this).is(':checked') },
    dataType: 'json',
    
    success: function(response) {
      if(response.status) {
        alert('保存に成功しました。');
        if($('#is-parent').is(':checked')) {          
          $('.checked-final').prop('disabled', false);
        } else {
          if(response.number_ordered >= 1) {
            $('.checked-final').prop('disabled', true);
          } else {
            $('.checked-final').prop('disabled', false);
          }
        }
        console.log(response.can_change_type);
        $('#is-parent').prop('disabled', !response.can_change_type)
      }
    },
  })
})

$(function () {
  if ($('#is-parent').length > 0) {
    ele_singledate()
  }
})
