$(document).ready(function() {
  $(document).on('change', '#bank_account_bank_id', function() {
    const bank_id = $(this).val();
    var $element = $(this);

    $.ajax({
      url: `/banks/${bank_id}/branches`,
      type: 'GET',
      success: function (e) {
        let data = e.branches.map(function (item) {
          return { id: item[0], text: item[1] }
        })

        $bank_branch_id = $element.closest('.bank-table').find('#bank_account_branch_id');

        $bank_branch_id.empty();
        $bank_branch_id.select2({
          width: '100%',
          theme: 'bootstrap',
          placeholder: '選択してください',
          allowClear: true,
          data: data,
          language: {
            noResults: function () {
              return '見つかりません。';
            },
          },
          dropdownParent: $('#modal-bank-accounts-form')
        });

        $bank_branch_id.val('').change();
      }
    });
  })

  $(document).on('click', '.btn-edit-bank-account', function() {
    const bank_account_id = $(this).data('id');

    $.ajax({
      url: `/bank_accounts/${bank_account_id}/edit`,
      type: 'GET',
      dataType: 'script'
    })
  })
})